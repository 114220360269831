// import ScrollingText from "../components/ScrollingText";
import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import blueCircle from "../image/blue-circle.png";
import pinkCircle from "../image/pink-circle.png";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) =>
	createStyles({
		galleryContainer: {
			backgroundColor: "#fff8fe",
			height: "100vh",
		},
		galleryTitle: {
			fontSize: 40,
			textAlign: "center",
			paddingTop: "35vh",
			fontFamily: "CeraCompactPro",
			fontWeight: "bold",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "20vh",
				fontSize: 35,
			},
		},
		galleryDescription: {
			fontSize: 30,
			textAlign: "center",
			fontFamily: "CeraCompactPro",
			marginLeft: "auto",
			marginRight: "auto",
			paddingTop: "3vh",
			width: "50vw",
			[theme.breakpoints.down("sm")]: {
				fontSize: 25,
			},
		},
		circleGalleryPink: {
			position: "absolute",
			marginTop: "-40vh",
			marginLeft: "10vw",
			height: 400,

			[theme.breakpoints.down("sm")]: {
				height: 120,
				marginTop: -150,
				marginLeft: 120,
			},
		},
		circleGalleryBlue: {
			position: "absolute",
			marginTop: "-20vh",
			marginLeft: "20vw",
			height: 300,
			[theme.breakpoints.down("sm")]: {
				height: 100,
				marginTop: -100,
				marginLeft: 150,
			},
		},
	})
);

// const texts = ["UX/UI Designer", "developer", "creative designer"];
// const options = {
// 	height: 16,
// 	fontSize: 16,
// 	containerStyles: {
// 		top: "2px",
// 	},
// };
// const Text = (
// 	<ScrollingText
// 		texts={texts}
// 		containerStyles={{
// 			color: "red",
// 			fontFamily: "arial",
// 		}}
// 	/>
// );

const Gallery = ({ t }) => {
	const classes = useStyles();

	return (
		<Grid md={12} className={classes.galleryContainer}>
			<Typography className={classes.galleryTitle}>
				{t("galleryTitle")}
			</Typography>
			<Typography className={classes.galleryDescription}>
				{t("galleryDescription")}
			</Typography>
			<img
				src={pinkCircle}
				alt="pink circle"
				className={classes.circleGalleryPink}
			/>
			<img
				src={blueCircle}
				alt="blue circle"
				className={classes.circleGalleryBlue}
			/>
		</Grid>
	);
};

export default withNamespaces()(Gallery);
