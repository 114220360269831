import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Creative from "./Creative/Creative";
import Blog from "./Blog/Blog";
import RessourcesDebutant from "./Articles/ressoucesUX-UI-beggin";
import Cypress from "./Articles/tester-react-cypress";
import Home from "./Home/Home";
import history from "./history";

export default class Routes extends Component {
	render() {
		return (
			<Router history={history}>
				<Switch>
					<Route path="/About" component={About} />
					<Route path="/Contact" component={Contact} />
					<Route path="/Blog" component={Blog} />
					<Route path="/RessourcesDebutant" component={RessourcesDebutant} />
					<Route path="/Creative" component={Creative} />
					<Route path="/Cypress" component={Cypress} />
					<Route component={Home} />
				</Switch>
			</Router>
		);
	}
}
