import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
	createStyles({
		screen: {
			backgroundColor: "#f1f2fd",
			height: "100%",
			paddingTop: "30vh",
			paddingBottom: "20vh",
			paddingLeft: "20vw",
			paddingRight: "20vw",
			fontFamily: "CeraCompactPro",
			[theme.breakpoints.down("sm")]: {
				paddingLeft: "10vw",
				paddingRight: "10vw",
				paddingTop: "20vh",
			},
		},
		title: {
			textAlign: "center",
			fontSize: 35,
			fontWeight: "bold",
			fontFamily: "CeraCompactPro",
		},
		description: {
			textAlign: "center",
			paddingTop: "3vh",
			fontSize: 20,
			marginBottom: "10vh",
			fontFamily: "CeraCompactPro",
		},
		firstTitleArticle: { marginBottom: "2vh" },
		titleInArticle: { marginTop: "5vh", marginBottom: "2vh" },
		textArticle: { marginBottom: "2vh", fontFamily: "CeraCompactPro" },
	})
);

const ArticleRessourcesDebutant = () => {
	const classes = useStyles();

	return (
		<Grid className={classes.screen}>
			<Grid>
				<Typography className={classes.title} variant="h2">
					UX/UI RESOURCES
				</Typography>
				<Typography className={classes.description}>
					Hey! Ce document a été créé par Laurine OBRIOT. Je suis une designeuse
					UX/UI de 22 ans et j’ai rédigé ce document plein de ressources utiles
					pour les designers en herbe. J’espère que cela pourra vous aider!
				</Typography>
			</Grid>
			<Grid className={classes.body}>
				<Typography variant="h4" className={classes.firstTitleArticle}>
					LES JOBS
				</Typography>
				<Typography className={classes.textArticle}>
					Un concepteur UX est responsable de la stratégie, de la recherche, de
					l’architecture de l’information et de la convivialité d’un site web ou
					d’une application.
				</Typography>
				<Typography className={classes.textArticle}>
					Il existe de nombreux rôles dans l’UX/UI, notamment:
				</Typography>
				<ul>
					<li>UX Consultant</li>
					<li>UX Designer</li>
					<li>UX Researcher</li>
					<li>UX Analyst</li>
					<li>UX Architect</li>
					<li>UX Strategist</li>
					<li>Content Designer</li>
					<li>Researcher</li>
					<li>Visual Designer</li>
					<li>UI Designer</li>
					<li>Information Architect</li>
					<li>Interaction Designer</li>
				</ul>
				<Typography variant="h4" className={classes.titleInArticle}>
					LISTE DES LOGICIELS
				</Typography>
				<Typography className={classes.textArticle}>
					Les 6 premiers outils sont les meilleurs pour l’UI, les 6 derniers
					sont les meilleurs pour l’UX. Lorsque vous obtenez un emploi en UX/UI,
					beaucoup d’entre eux seront disponibles gratuitement, c’est pourquoi
					je recommande d’utiliser un logiciel libre pour commencer. Il est sage
					de vous familiariser avec autant de logiciels différents que possible,
					car différentes équipes de conception utilisent différents outils,
					mais vous n’avez PAS besoin d’être un maître dans tout. Les outils
					utilisés seront toujours répertoriés via une description de poste.
				</Typography>
				<Typography className={classes.textArticle}>
					J’ai tendance à utiliser Figma, XD, Sketch et Flowmapp:
				</Typography>
				<ol>
					<li>Figma - Gratuit avec des plans payants supplémentaires</li>
					<li>XD - Gratuit avec des plans payants supplémentaires</li>
					<li>Sketch - 99€ l’année</li>
					<li>InVision - Gratuit avec des plans payants supplémentaires</li>
					<li>Framer - Gratuit avec des plans payants supplémentaires</li>
					<li>Proto.io - A partir de 24€ par mois</li>
					<li>Azure - A partir de 25€ par mois</li>
					<li>Balsamiq - A partir de 9€ par mois</li>
					<li>Marvel - Gratuit avec des plans payants supplémentaires</li>
					<li>Flowmapp - Gratuit avec des plans payants supplémentaires</li>
					<li>Treejack - 166€ le mois</li>
					<li>Wireframe.cc - Gratuit</li>
				</ol>
				<Typography className={classes.textArticle}>
					Il est également très judicieux de vous familiariser avec Adobe
					Creative Suite dans son ensemble. Illustrator, Photoshop et Premiere
					Pro à des fins de démonstration en particulier) sont essentiels. Cela
					peut être très coûteux car il totalise un peu moins de 60€ par mois
					pour toute la suite et près de 20€ par mois pour un étudiant, et
					chaque application a l’unité coûte environ 20€ par mois.
				</Typography>
				<Typography variant="h4" className={classes.titleInArticle}>
					LISTE DE LIVRES À LIRE
				</Typography>
				<Typography className={classes.textArticle}>
					Il existe des livres incroyables qui vous aideront dans votre voyage
					UX/UI. Ici, j’ai répertorié quelques-uns de mes favoris et certains
					des plus recommandés:
				</Typography>
				<Typography className={classes.textArticle}>
					Tout d’abord, si vous prévoyez de travailler en freelance, lisez Work
					for Money, Design for Love: Answers to the Most Frequently Asked
					Questions About Starting and Running a Successful Design Business
				</Typography>
				<ol
					style={{
						display: "flex",
						flexDirection: "column",
						fontFamily: "CeraCompactPro",
					}}
				>
					<a href="https://www.amazon.com/Non-Designers-Design-Book-3rd/dp/0321534042">
						1- The Non-Designer's Design Book
					</a>
					<a href="https://www.amazon.com/UX-Design-2020-Beginners-Experience-ebook/dp/B08BZ11L9T">
						2 - UX Design 2020: The Ultimate Beginner's Guide to User Experience
					</a>
					<a href="https://www.amazon.com/Design-Everyday-Things-Revised-Expanded/dp/0465050654The">
						3 - Design of Everyday Things: Revised and Expanded Edition
					</a>
					<a href="https://www.amazon.com/UX-Beginners-Crash-Course-Lessons-ebook/dp/B01A64DFL0">
						4 - UX for Beginners: A Crash Course in 100 Short Lessons
					</a>
					<a href="https://www.amazon.com/Project-Guide-Design-experience-designers-ebook/dp/B007N4WU6S">
						5 - A Project Guide to UX Design: For user experience designers in
						the field or in the making
					</a>
					<a href="https://www.amazon.com/Dont-Make-Think-Revisited-Usability/dp/0321965515">
						6 - Don't Make Me Think, Revisited: A Common Sense Approach to Web
						Usabiaty
					</a>
					<a href="https://www.amazon.com/Design-Introduction-Intuitive-User-Friendly-Experience-ebook/dp/B08B518FSQ">
						7 - UX / UI Design: Introduction Guide To Intuitive Design And
						User-Friendly Experience
					</a>
					<a href="https://www.amazon.com/Designing-Mind-Understanding-Interface-Guidelines/dp/0124079148">
						8 - Designing with the Mind in Mind: Simple Guide to Understanding
						User Interface Design Guideanes
					</a>
					<a href="https://www.amazon.com/User-Friendly-Hidden-Design-Changing/dp/0374279756?ref_=Oct_s9_apbd_obs_hd_bw_b133&pf_rd_r=12CWVHJ5EYM0QBQM0JZP&pf_rd_p=69b56706-8d87-5e1f-93dd-8965d939c9ea&pf_rd_s=merchandised-search-10&pf_rd_t=BROWSE&pf_rd_i=4033">
						9 - User Friendly: How the Hidden Rules of Design Are Changing the
						Way We ave, Work, and Play
					</a>
					<a href="https://www.amazon.com/Articulating-Design-Decisions-Communicate-Stakeholders/dp/1491921560">
						10 - Articulating Design Decisions: Communicate with Stakeholders,
						Keep Your Sanity, and Deaver the Best User Experience
					</a>
					<a href="https://www.amazon.com/Hooked-How-Build-Habit-Forming-Products/dp/1591847788/ref=pd_sim_14_7?_encoding=UTF8&pd_rd_i=1591847788&pd_rd_r=46569c1b-5409-47e4-9df3-4825baa700c5&pd_rd_w=nsRA2&pd_rd_wg=Mjlg3&pf_rd_p=37f7dfa1-8c4e-4106-9502-a190ed3a2f4f&pf_rd_r=JZKJKAT15AMR1VR1E203&psc=1&refRID=JZKJKAT15AMR1VR1E203">
						11 - Hooked: How to Build Habit-Forming Products
					</a>
					<a href="https://www.amazon.com/INSPIRED-Create-Tech-Products-Customers/dp/1119387507/ref=pd_sbs_14_1/145-0357304-0039924?_encoding=UTF8&pd_rd_i=1119387507&pd_rd_r=e73ddf87-2667-42f9-b960-cfda8859a4ec&pd_rd_w=TvCvY&pd_rd_wg=TUuC6&pf_rd_p=b65ee94e-1282-43fc-a8b1-8bf931f6dfab&pf_rd_r=0ZG2S0G7JKRFHB99PTRK&psc=1&refRID=0ZG2S0G7JKRFHB99PTRK">
						12 - Inspired: How to Create Tech Products Customers Love
					</a>
				</ol>
				<Typography variant="h4" className={classes.titleInArticle}>
					LISTE DES COURS
				</Typography>
				<Typography className={classes.textArticle}>
					Il existe de nombreux cours disponibles en ligne et dans des
					institutions qui peuvent vous aider dans votre parcours de conception.
				</Typography>
				<Typography className={classes.textArticle}>
					UX/UI est interdisciplinaire, ce qui signifie qu’il n’y a pas un seul
					diplôme qui couvre le domaine. Certains designers viennent de
					formations de premier cycle en informatique, conception graphique,
					interaction homme-machine, conception d’interaction, architecture de
					l’information, sociologie, psychologie, marketing et publicité.
				</Typography>
				<Typography className={classes.textArticle}>
					La raison en est due aux compétences requises par l’UX/UI - voici
					quelques-unes des compétences non techniques, industrielles et
					croisées. Cliquez sur l’image ci-dessous pour plus d’informations.
				</Typography>
				<img
					// src={}
					alt="skills"
					href="https://careerfoundry.com/en/blog/ux-design/what-qualifications-do-you-need-to-be-a-ux-designer/"
				/>
				<Typography className={classes.textArticle}>
					Voici quelques excellents cours en ligne:
				</Typography>
				<ol
					style={{
						display: "flex",
						flexDirection: "column",
						fontFamily: "CeraCompactPro",
					}}
				>
					<a href="https://www.udemy.com/">1 - Udemy - Payant</a>
					<a href="https://www.futurelearn.com/">2 - Future Learn - Gratuit</a>
					<a href="https://www.lovecircular.com">3 - Circular - Payant</a>
					<a href="https://careerfoundry.com/">4 - Career Foundary - Payant</a>
					<a href="https://www.coursera.org/">5 - Coursera - Payant</a>
					<a href="http://www.extension.harvard.edu/">
						6 - HARVARD Extension School - Gratuit
					</a>
				</ol>
				<Typography variant="h4" className={classes.titleInArticle}>
					LECTURES EN LIGNE
				</Typography>
				<Typography className={classes.textArticle}>
					Je suis une grande fan de Médium pour le contenu UX/UI. Voici une
					liste de quelques bonnes lectures de Médium, que je trouve vraiment
					utiles.
				</Typography>
				<ol
					style={{
						display: "flex",
						flexDirection: "column",
						fontFamily: "CeraCompactPro",
					}}
				>
					<a href="https://webflow.com/blog/from-sketch-to-webflow-how-to-turn-mockups-into-live-websites">
						1 -
						https://webflow.com/blog/from-sketch-to-webflow-how-to-turn-mockups-into-live-websites
					</a>
					<a href="https://uxdesign.cc/how-to-develop-an-eye-for-design-c5a4c64bb26c">
						2 -
						https://uxdesign.cc/how-to-develop-an-eye-for-design-c5a4c64bb26c
					</a>
					<a href="https://webflow.com/blog/39-must-have-tools-and-apps-for-freelance-designers">
						3 -
						https://webflow.com/blog/39-must-have-tools-and-apps-for-freelance-designers
					</a>
					<a href="https://uxdesign.cc/what-makes-a-bad-design-302d1ca76185">
						4 - https://uxdesign.cc/what-makes-a-bad-design-302d1ca76185
					</a>
					<a href="https://uxplanet.org/how-to-land-a-career-in-ux-design-with-zero-qualifications-16ddcb3b3eda">
						5 -
						https://uxplanet.org/how-to-land-a-career-in-ux-design-with-zero-qualifications-16ddcb3b3eda
					</a>
					<a href="https://uxplanet.org/how-i-landed-a-ux-design-job-in-a-month-with-no-design-background-or-bootcamp-d61182258dc2">
						6 -
						https://uxplanet.org/how-i-landed-a-ux-design-job-in-a-month-with-no-design-background-or-bootcamp-d61182258dc2
					</a>
					<a href="https://medium.com/bestfolios/6-awesome-portfolio-websites-by-amazon-designers-533c6d3c8c4">
						7 -
						https://medium.com/bestfolios/6-awesome-portfolio-websites-by-amazon-designers-533c6d3c8c4
					</a>
					<a href="https://uxdesign.cc/what-i-learnt-from-rejecting-design-candidates-and-what-you-shouldnt-do-as-an-applicant-5235b6734e6a">
						8 -
						https://uxdesign.cc/what-i-learnt-from-rejecting-design-candidates-and-what-you-shouldnt-do-as-an-applicant-5235b6734e6a
					</a>
					<a href="https://medium.com/bestfolios/5-student-design-portfolios-done-right-6ca3cc8c5e8f">
						9 -
						https://medium.com/bestfolios/5-student-design-portfolios-done-right-6ca3cc8c5e8f
					</a>
					<a href="https://uxdesign.cc/8-things-i-learned-in-my-first-2-months-as-a-ux-designer-at-google-b9b9fc569f62">
						10 -
						https://uxdesign.cc/8-things-i-learned-in-my-first-2-months-as-a-ux-designer-at-google-b9b9fc569f62
					</a>
					<a href="https://uxdesign.cc/20-project-ideas-to-kickstart-your-ux-career-44f606aebe09">
						11 -
						https://uxdesign.cc/20-project-ideas-to-kickstart-your-ux-career-44f606aebe09
					</a>
					<a href="https://uxdesign.cc/how-to-improve-your-product-ui-designers-checklist-58510947e6ab">
						12 -
						https://uxdesign.cc/how-to-improve-your-product-ui-designers-checklist-58510947e6ab
					</a>
				</ol>
				<Typography variant="h4" className={classes.titleInArticle}>
					CHAINES YOUTUBE
				</Typography>
				<Typography className={classes.textArticle}>
					Pour vous aider dans votre voyage, voici quelques excellentes chaînes
					que vous devriez regarder:
				</Typography>
				<ol
					style={{
						display: "flex",
						flexDirection: "column",
						fontFamily: "CeraCompactPro",
					}}
				>
					<a href="https://www.youtube.com/channel/UC-b3c7kxa5vU-bnmaROgvog">
						1 - The Futur
					</a>
					<a href="https://www.youtube.com/user/charlimarieTV">
						2 - CharliMarieTV
					</a>
					<a href="https://www.youtube.com/c/LaithWallace">3 - Laith Wallace</a>
					<a href="https://www.youtube.com/channel/UCSdp5logiFTM3SyLJrHabOQ">
						4 - Maex
					</a>
					<a href="https://www.youtube.com/channel/UCN7dywl5wDxTu1RM3eJ_h9Q">
						5 - Flux
					</a>
					<a href="https://www.youtube.com/channel/UCZHkx_OyRXHb1D3XTqOidRw">
						6 - Sketch Together
					</a>
				</ol>
				<Typography variant="h4" className={classes.titleInArticle}>
					RESEAUX SOCIAUX
				</Typography>
				<Typography className={classes.textArticle}>
					Voici quelques bons endroits pour trouver une communauté et partager
					votre travail:
				</Typography>
				<ol
					style={{
						display: "flex",
						flexDirection: "column",
						fontFamily: "CeraCompactPro",
					}}
				>
					<a href="https://twitter.com/?lang=en">1 - Twitter</a>
					<a href="https://www.instagram.com/?hl=en">2 - Instagram</a>
					<a href="https://www.behance.net/">3 - Behance</a>
					<a href="https://www.linkedin.com/">4 - LinkedIn</a>
					<a href="https://dribbble.com/">5 - Dribbble</a>
					<a href="https://www.pinterest.com/">6 - Pinterest</a>
				</ol>
				<Typography variant="h4" className={classes.titleInArticle}>
					PROCEDE DE DESIGN
				</Typography>
				<Typography className={classes.textArticle}>
					Le processus de conception du produit ou de l’UX/UI comprend 5 étapes
					clés illustrées dans le diagramme ci-dessous. Cliquez sur le diagramme
					pour une description complète du processus.
				</Typography>
				<img
					// src={}
					alt="Design Process"
					href="https://medium.com/nyc-design/ux-ui-design-process-for-beginner-753952bb2241"
				/>
				<Typography className={classes.textArticle}>
					Merci d’avoir lu, j’espère que cela vous a été utile. N’hésitez pas à
					partager ces informations.
				</Typography>
			</Grid>
		</Grid>
	);
};

export default ArticleRessourcesDebutant;
