import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import "../index.css";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: "#f0f3fd",
			width: "100vw",
			height: "100vh",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.down("xs")]: {
				width: "100vw",
				height: "100vh",
			},
		},
		bigTitle: {
			fontSize: 45,
			textAlign: "center",
			width: "50vw",
			fontFamily: "CeraCompactPro",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.down("md")]: {
				fontSize: 40,
				width: "70vw",
				marginLeft: "15vw",
			},
			[theme.breakpoints.down("xs")]: {
				fontSize: 27,
				width: "80vw",
				display: "flex",
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
	})
);

const Home = () => {
	const classes = useStyles();

	return (
		<Grid className={classes.container}>
			<Typography className={classes.bigTitle}>
				I'm getting a makeover, I'll be back soon!
			</Typography>
		</Grid>
	);
};

export default Home;
