import { Typography, Grid } from "@material-ui/core";
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "../index.css";

const useStyles = makeStyles((theme) =>
	createStyles({
		screen: {
			display: "flex",
			justifyContent: "center",
			backgroundColor: "#f1f2fd",
			height: "100vh",
			[theme.breakpoints.down("sm")]: {
				height: "200vh",
			},
		},
		whoami: {
			paddingTop: "20vh",
			paddingLeft: "20vh",
			[theme.breakpoints.down("sm")]: {
				padding: "5vw",
				paddingTop: "-10vh",
				paddingBottom: "10vh",
			},
		},
		awesome: {
			paddingTop: "10vh",
			fontFamily: "CeraCompactPro",
			fontWeight: "bold",
			[theme.breakpoints.down("sm")]: {
				fontSize: 25,
			},
		},
		qualities: {
			marginTop: "1vh",
			fontStyle: "italic",
			fontFamily: "CeraCompactPro",
			fontSize: 18,
			[theme.breakpoints.down("sm")]: {
				marginTop: "3vh",
			},
		},
		job: {
			fontSize: 20,
			paddingTop: "3vh",
			fontFamily: "CeraCompactPro",
		},
		description: {
			fontSize: 20,
			width: "40vw",
			marginTop: "3vh",
			fontFamily: "CeraCompactPro",
			[theme.breakpoints.down("sm")]: {
				width: "80vw",
				marginTop: "5vh",
			},
		},
		containerQualities: {
			marginTop: "30vh",
			paddingLeft: "15vw",
			[theme.breakpoints.down("sm")]: {
				width: "80vw",
				paddingLeft: 0,
				marginTop: 0,
			},
		},
	})
);

const WhoAmI = () => {
	const classes = useStyles();

	return (
		<Grid container md={12} className={classes.screen}>
			<Grid item md={6} className={classes.whoami}>
				<Typography variant="h3" className={classes.awesome}>
					Hi, I'm Laurine.
				</Typography>
				<Typography className={classes.job}>
					I'm a developer & designer based in Paris.
				</Typography>
				<Typography className={classes.description}>
					I am a front-end developer, specialized in UX / UI design. I enjoy
					creating digital products and experiences, I am comfortable working on
					all aspects of the product cycle. I also took training in natural
					referencing (SEO). I like create stunning user interfaces and provide
					production ready resources.
				</Typography>
			</Grid>
			<Grid item md={6} className={classes.containerQualities}>
				<Typography className={classes.qualities}>
					Product strategy and ideation
				</Typography>
				<Typography className={classes.qualities}>
					Usability and Accessibility heuristic audits
				</Typography>
				<Typography className={classes.qualities}>Product Ownership</Typography>
				<Typography className={classes.qualities}>
					Wireframing and iteration
				</Typography>
				<Typography className={classes.qualities}>UX writing</Typography>
				<Typography className={classes.qualities}>
					Interaction and motion design
				</Typography>
				<Typography className={classes.qualities}>
					Layout and typography
				</Typography>
				<Typography className={classes.qualities}>Campaign creative</Typography>
				<Typography className={classes.qualities}>IT development</Typography>
				<Typography className={classes.qualities}>
					Gimp and Inkscape as Software
				</Typography>
			</Grid>
		</Grid>
	);
};

export default WhoAmI;
