import React from "react";
import WhoAmI from "../components/WhoAmI";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import blueCircle from "../image/blue-circle.png";
import pinkCircle from "../image/pink-circle.png";
import SEO from "../image/analytics.svg";
import Design from "../image/design.svg";
import Code from "../image/code.svg";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) =>
	createStyles({
		screen: {
			fontFamily: "CeraCompactPro",
		},
		competences: {
			display: "flex",
			justifyContent: "center",
			backgroundColor: "#fff8fe",
		},
		SkillsContainer: { backgroundColor: "#fff8fe", height: "100vh" },
		circleGalleryBlue: {
			position: "absolute",
			marginTop: "35vh",
			marginLeft: "15vw",
			height: 400,
			[theme.breakpoints.down("sm")]: {
				height: 100,
				marginTop: 300,
				marginLeft: 150,
			},
		},
		circleGalleryPink: {
			position: "absolute",
			marginTop: "30vh",
			marginLeft: "10vw",
			height: 300,
			[theme.breakpoints.down("sm")]: {
				height: 120,
				marginTop: 350,
				marginLeft: 120,
			},
		},
		titleCompetence: {
			fontSize: 40,
			textAlign: "center",
			paddingTop: "35vh",
			paddingLeft: "46vw",
			fontFamily: "CeraCompactPro",
			fontWeight: "bold",
			position: "absolute",
			[theme.breakpoints.down("sm")]: {
				fontSize: 35,
				paddingLeft: "40vw",
				paddingTop: "20vw",
			},
		},
		description: {
			fontSize: 25,
			textAlign: "center",
			fontFamily: "CeraCompactPro",
			marginLeft: "auto",
			marginRight: "auto",
			paddingTop: "45vh",
			paddingLeft: "25vw",
			width: "50vw",
			position: "absolute",
			[theme.breakpoints.down("sm")]: {
				fontSize: 20,
				paddingLeft: "27vw",
				paddingTop: "40vw",
			},
		},
		titleCode: {
			fontSize: 35,
			fontFamily: "CeraCompactPro",
			fontWeight: "bold",
			[theme.breakpoints.down("sm")]: {
				marginLeft: "auto",
				marginRight: "auto",
				marginTop: "3vh",
			},
		},
		descriptionCode: {
			fontSize: 22,
			fontFamily: "CeraCompactPro",
			paddingTop: "3vh",
			width: "50vw",
			paddingBottom: "2vh",
			[theme.breakpoints.down("sm")]: {
				width: "80vw",
			},
		},
		competenceCode: {
			display: "flex",
			justifyContent: "center",
			backgroundColor: "#f0f3fd",
			padding: "10vw",
			paddingTop: "20vh",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		competenceCodeTelephone: {
			display: "flex",
			justifyContent: "center",
			backgroundColor: "#f0f3fd",
			padding: "10vw",
			paddingTop: "20vh",
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
		},
		image: {
			paddingRight: "3vw",
			[theme.breakpoints.down("sm")]: {
				padding: 0,
			},
		},
		titleList: {
			fontWeight: "bold",
			fontFamily: "CeraCompactPro",
			fontSize: 25,
		},
		descriptionList: {
			fontFamily: "CeraCompactPro",
			fontSize: 20,
			paddingBottom: "2vh",
		},
		titleReact: {
			fontWeight: "bold",
			fontFamily: "CeraCompactPro",
			fontSize: 25,
			marginBottom: "1vh",
		},
		descriptionReact: {
			fontFamily: "CeraCompactPro",
			fontSize: 20,
		},
	})
);

const About = ({ t }) => {
	const classes = useStyles();

	return (
		<Grid className={classes.screen}>
			<WhoAmI />
			<Grid direction="column" className={classes.competences}>
				<Grid direction="column" className={classes.SkillsContainer}>
					<Typography className={classes.titleCompetence}>Skills</Typography>
					<Typography className={classes.description}>
						I have a hybrid profile that goes beyond traditional development.
						Knowing these two jobs allows me to stay aware of new technologies,
						as well as being better, given that I have a better knowledge of the
						needs of these two facets.
					</Typography>
					<img
						src={pinkCircle}
						alt="pink circle"
						className={classes.circleGalleryPink}
						height="500"
					/>
					<img
						src={blueCircle}
						alt="blue circle"
						className={classes.circleGalleryBlue}
						height="300"
					/>
				</Grid>
				{/* VERSION TELEPHONE */}
				<Grid
					xs={12}
					direction="column"
					className={classes.competenceCodeTelephone}
				>
					<img
						src={Code}
						alt="Code"
						height="200"
						width="300"
						className={classes.image}
					/>
					<Grid xs={12} direction="column">
						<Grid xs={12} flexDirection="column">
							<Typography className={classes.titleCode}>
								{t("aboutDevelopmentTitle")}
							</Typography>
							<Typography className={classes.descriptionCode}>
								{t("aboutDescription")}
							</Typography>
							<ul>
								<li>
									<Typography className={classes.titleList}>
										{t("aboutMobileTitle")}
									</Typography>
									<Typography className={classes.descriptionList}>
										{t("aboutMobileDescription")}
									</Typography>
								</li>
								<li>
									<Typography className={classes.titleList}>
										{t("aboutWebTitle")}
									</Typography>
									<Typography className={classes.descriptionList}>
										{t("aboutWebDescription")}
									</Typography>
								</li>
							</ul>
						</Grid>
						<Grid
							md={12}
							style={{
								display: "flex",
								flexDirection: "row",
								marginTop: "5vw",
							}}
						>
							<Grid
								xs={12}
								md={7}
								style={{
									display: "flex",
									flexDirection: "column",
									marginRight: "5vw",
									marginLeft: -350,
								}}
							>
								<Typography className={classes.titleReact}>
									{t("aboutReactTitle")}
								</Typography>
								<Typography className={classes.descriptionReact}>
									{t("aboutReactDescription")}
								</Typography>
							</Grid>
							<Grid
								xs={12}
								md={7}
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: 100,
								}}
							>
								<Typography className={classes.titleReact}>
									{t("aboutNativeTitle")}
								</Typography>
								<Typography className={classes.descriptionReact}>
									{t("aboutNativeDescription")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid direction="column" className={classes.competenceCodeTelephone}>
					<img
						src={Design}
						alt="Design"
						height="200"
						width="300"
						className={classes.image}
					/>
					<Grid direction="column">
						<Typography className={classes.titleCode}>
							{t("designTitle")}
						</Typography>
						<Typography className={classes.descriptionCode}>
							{t("designDescription")}
						</Typography>
						<ul>
							<li>
								<Typography className={classes.titleList}>
									{t("designUXDesign")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("designUXDescription")}
								</Typography>
							</li>
							<li>
								<Typography className={classes.titleList}>
									{t("designUIDesign")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("designUIDescription")}
								</Typography>
							</li>
						</ul>
					</Grid>
				</Grid>
				<Grid
					xs={12}
					direction="column"
					className={classes.competenceCodeTelephone}
				>
					<img
						src={SEO}
						alt="SEO"
						height="200"
						width="300"
						style={{ paddingLeft: 0, marginLeft: 0 }}
						className={classes.image}
					/>
					<Grid xs={12} direction="row">
						<Typography className={classes.titleCode}>
							{t("SEOTitle")}
						</Typography>
						<Typography className={classes.descriptionCode}>
							{t("SEODescription")}
						</Typography>
						<ul>
							<li>
								<Typography className={classes.titleList}>
									{t("SEOAnalytics")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("SEOAnalyticsDescription")}
								</Typography>
							</li>
							<li>
								<Typography className={classes.titleList}>
									{t("SEOContentTitle")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("SEOContentDescription")}
								</Typography>
							</li>
						</ul>
					</Grid>
				</Grid>

				{/* VERSION SUP SM */}
				<Grid xs={12} direction="row" className={classes.competenceCode}>
					<img
						src={Code}
						alt="Code"
						height="200"
						width="300"
						className={classes.image}
					/>
					<Grid xs={12} direction="column">
						<Grid xs={12} flexDirection="column">
							<Typography className={classes.titleCode}>
								{t("aboutDevelopmentTitle")}
							</Typography>
							<Typography className={classes.descriptionCode}>
								{t("aboutDescription")}
							</Typography>
							<ul>
								<li>
									<Typography className={classes.titleList}>
										{t("aboutMobileTitle")}
									</Typography>
									<Typography className={classes.descriptionList}>
										{t("aboutMobileDescription")}
									</Typography>
								</li>
								<li>
									<Typography className={classes.titleList}>
										{t("aboutWebTitle")}
									</Typography>
									<Typography className={classes.descriptionList}>
										{t("aboutWebDescription")}
									</Typography>
								</li>
							</ul>
						</Grid>
						<Grid
							md={12}
							style={{
								display: "flex",
								flexDirection: "row",
								marginTop: "5vw",
							}}
						>
							<Grid
								xs={12}
								md={7}
								style={{
									display: "flex",
									flexDirection: "column",
									marginRight: "5vw",
									marginLeft: -350,
								}}
							>
								<Typography className={classes.titleReact}>
									{t("aboutReactTitle")}
								</Typography>
								<Typography className={classes.descriptionReact}>
									{t("aboutReactDescription")}
								</Typography>
							</Grid>
							<Grid
								xs={12}
								md={7}
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: 100,
								}}
							>
								<Typography className={classes.titleReact}>
									{t("aboutNativeTitle")}
								</Typography>
								<Typography className={classes.descriptionReact}>
									{t("aboutNativeDescription")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid direction="row" className={classes.competenceCode}>
					<Grid direction="column">
						<Typography className={classes.titleCode}>
							{t("designTitle")}
						</Typography>
						<Typography className={classes.descriptionCode}>
							{t("designDescription")}
						</Typography>
						<ul>
							<li>
								<Typography className={classes.titleList}>
									{t("designUXDesign")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("designUXDescription")}
								</Typography>
							</li>
							<li>
								<Typography className={classes.titleList}>
									{t("designUIDesign")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("designUIDescription")}
								</Typography>
							</li>
						</ul>
					</Grid>
					<img
						src={Design}
						alt="Design"
						height="200"
						width="300"
						className={classes.image}
					/>
				</Grid>
				<Grid xs={12} direction="row" className={classes.competenceCode}>
					<img
						src={SEO}
						alt="SEO"
						height="200"
						width="400"
						className={classes.image}
					/>
					<Grid xs={12} direction="row">
						<Typography className={classes.titleCode}>
							{t("SEOTitle")}
						</Typography>
						<Typography className={classes.descriptionCode}>
							{t("SEODescription")}
						</Typography>
						<ul>
							<li>
								<Typography className={classes.titleList}>
									{t("SEOAnalytics")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("SEOAnalyticsDescription")}
								</Typography>
							</li>
							<li>
								<Typography className={classes.titleList}>
									{t("SEOContentTitle")}
								</Typography>
								<Typography className={classes.descriptionList}>
									{t("SEOContentDescription")}
								</Typography>
							</li>
						</ul>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withNamespaces()(About);
