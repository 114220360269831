import React from "react";
// import { makeStyles, createStyles } from "@material-ui/core/styles";
import Gallery from "../components/Gallery";

// const useStyles = makeStyles((theme) => createStyles({}));

const Creative = (props) => {
	// const classes = useStyles();

	return <Gallery />;
};

export default Creative;
