import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
	Grid,
	Button,
	Typography,
	Link,
	ButtonBase,
	Paper,
} from "@material-ui/core";
import cypress from "../image/cypressReact.svg";
import UX from "../image/UXUI.svg";
import "../index.css";
import { withNamespaces } from "react-i18next";
import i18n from "../i18n";

const useStyles = makeStyles((theme) =>
	createStyles({
		screen: {
			backgroundColor: "#e7e1fd",
			height: "200vh",
		},
		title: {
			paddingTop: "20vh",
			textAlign: "center",
			fontSize: 40,
			fontFamily: "CeraCompactPro",
			[theme.breakpoints.down("sm")]: {
				fontSize: 30,
				paddingTop: "15vh",
			},
		},
		description: {
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			paddingTop: "3vh",
			fontSize: 20,
			fontFamily: "CeraCompactPro",
			paddingBottom: "5vh",
			[theme.breakpoints.down("sm")]: {
				fontSize: 15,
				width: "80vw",
			},
			[theme.breakpoints.up("md")]: { width: "60vw", textAlign: "center" },
		},
		containerArticles: {
			paddingTop: "5vw",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "15vw",
				display: "block",
				marginLeft: "auto",
				marginRight: "auto",
				width: "90vw",
			},
		},
		listeArticles: {
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.down("sm")]: {
				padding: 0,
				margin: 0,
				width: "70vw",
			},
			[theme.breakpoints.up("md")]: { paddingLeft: "10vw" },
		},
		article: {
			width: "40vw",
			height: "50vh",
			marginLeft: "7vw",
			fontFamily: "CeraCompactPro",
			[theme.breakpoints.down("sm")]: {
				width: "80vw",
				justifyContent: "center",
				margin: 0,
			},
			backgroundColor: "#fafa",
			marginBottom: -100,
			elevation: 1,
		},
		paperArticle: {
			height: "50vh",
			width: "50vw",
			paddingLeft: "2vw",
			paddingRight: "2vw",
			[theme.breakpoints.down("sm")]: {
				width: "100vw",
				height: "60vh",
				paddingLeft: "5vw",
				paddingRight: 0,
				margin: 0,
			},
		},
		imageArticle: {
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: "5vh",
			[theme.breakpoints.down("sm")]: {
				width: "50vw",
				height: "20vw",
			},
		},
		titleArticle: {
			display: "block",
			margin: "2vh",
			marginLeft: "10vw",
			fontWeight: "bold",
		},
		descriptionArticle: {
			marginLeft: "1vw",
			[theme.breakpoints.down("sm")]: { width: "70vw" },
		},
		// RIGHT ----------------------------
		right: {
			paddingLeft: "70vw",
			marginTop: -800,
			position: "fixed",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		buttonFrEn: {
			marginBottom: "3vh",
			fontWeight: "bold",
			marginTop: "3vw",
			width: "20vw",
		},
		titleCategories: {
			fontWeight: "bold",
			marginTop: "3vh",
		},
		listeCategories: {
			display: "flex",
			flexDirection: "column",
			alignItems: "start",
		},
	})
);

const Blog = ({ t }) => {
	const classes = useStyles();

	return (
		<Grid className={classes.screen}>
			<Grid>
				<Typography className={classes.title}>{t("blogTitle")}</Typography>
				<Typography className={classes.description}>
					{t("blogDescription")}
				</Typography>
			</Grid>
			<Grid flexDirection="row" className={classes.containerArticles}>
				<Grid className={classes.listeArticles}>
					<ButtonBase
						href="/RessourcesDebutant"
						focusRipple
						className={classes.article}
					>
						<Paper className={classes.paperArticle}>
							<img
								src={UX}
								alt="ressources UX"
								height="130"
								width="400"
								className={classes.imageArticle}
							/>
							<Typography className={classes.titleArticle}>
								{t("articleStartedWithDesignTitle")}
							</Typography>
							<Typography className={classes.descriptionArticle}>
								{t("articleStartedWithDesignDescription")}
							</Typography>
						</Paper>
					</ButtonBase>
					<ButtonBase
						href="/Cypress"
						focusRipple
						className={classes.article}
						style={{ marginTop: 200 }}
					>
						<Paper className={classes.paperArticle} $>
							<img
								src={cypress}
								alt="Cypress"
								height="150"
								width="350"
								className={classes.imageArticle}
							/>
							<Typography className={classes.titleArticle}>
								{t("articleStartedWithCypressTitle")}
							</Typography>
							<Typography className={classes.descriptionArticle}>
								{t("articleStartedWithCypressDescription")}
							</Typography>
						</Paper>
					</ButtonBase>
				</Grid>
				<Grid className={classes.right}>
					<Button
						variant="outlined"
						className={classes.buttonFrEn}
						onClick={() => {
							i18n.changeLanguage(i18n.language === "en" ? "fr" : "en");
						}}
					>
						{t("blogButton")}
					</Button>
					<Typography className={classes.titleCategories}>
						{t("blogCategoriesTitle")}
					</Typography>
					<Grid flexDirection="column" className={classes.listeCategories}>
						<Link
							component="button"
							variant="body2"
							onClick={() => {
								console.info("I'm a button.");
							}}
						>
							{t("blogCategoryReact")}
						</Link>
						<Link
							component="button"
							variant="body2"
							onClick={() => {
								console.info("I'm a button.");
							}}
						>
							{t("blogCategoryResponsive")}
						</Link>
						<Link
							component="button"
							variant="body2"
							onClick={() => {
								console.info("I'm a button.");
							}}
						>
							{t("blogCategoryUX")}
						</Link>
						<Link
							component="button"
							variant="body2"
							onClick={() => {
								console.info("I'm a button.");
							}}
						>
							{t("blogCategoryUI")}
						</Link>
						<Link
							component="button"
							variant="body2"
							onClick={() => {
								console.info("I'm a button.");
							}}
						>
							{t("blogCategoryTools")}
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withNamespaces()(Blog);
