import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ContactForm from "../components/ContactForm";
import "../index.css";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) =>
	createStyles({
		contact: {
			display: "flex",
			padding: "10vh",
			backgroundColor: "#e7e1fd",
			height: "70vh",
			paddingTop: "20vh",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		contactPhone: {
			display: "flex",
			backgroundColor: "#e7e1fd",
			height: "70vh",
			paddingTop: "20vh",
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
			[theme.breakpoints.only("sm")]: {
				display: "flex",
				backgroundColor: "#e7e1fd",
				height: "70vh",
				paddingTop: "20vh",
			},
		},
		title: {
			width: "70%",
			marginTop: "10vh",
			fontFamily: "CeraCompactPro",
			paddingLeft: "5vh",
			fontSize: 50,
			[theme.breakpoints.down("sm")]: {
				fontSize: 40,
				width: "80vw",
				marginTop: "0vh",
			},
		},
		description: {
			width: "70%",
			marginTop: "3vh",
			fontFamily: "CeraCompactPro",
			fontSize: 20,
			paddingLeft: "5vh",
			[theme.breakpoints.up("xl")]: {
				fontSize: 25,
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: 20,
				width: "80vw",
			},
		},
	})
);

const Contact = ({ t }) => {
	const classes = useStyles();

	return (
		<Grid>
			<Grid flexDirection="row" xs={12} className={classes.contact}>
				<Grid
					item
					direction="column"
					xs={12}
					md={7}
					style={{ backgroundColor: "#e7e1fd" }}
				>
					<Typography variant="h2" className={classes.title}>
						{t("contactTitle")}
					</Typography>
					<Typography className={classes.description}>
						{t("contactDescription")}
					</Typography>
				</Grid>
				<ContactForm />
			</Grid>
			{/* TELEPHONE */}
			<Grid
				flexDirection="column"
				container
				xs={12}
				className={classes.contactPhone}
			>
				<Grid item xs={12} style={{ backgroundColor: "#e7e1fd" }}>
					<Typography variant="h2" className={classes.title}>
						{t("contactTitle")}
					</Typography>
					<Typography className={classes.description}>
						{t("contactDescription")}
					</Typography>
				</Grid>
				<ContactForm />
			</Grid>
		</Grid>
	);
};

export default withNamespaces()(Contact);
