import React, { useState } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import "../index.css";

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			padding: "5vh",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			borderRadius: "20px",
			[theme.breakpoints.down("sm")]: {
				width: "70vw",
				marginBottom: "10vh",
			},
		},
		submit: {
			marginTop: "3vh",
			color: "#cfcae3",
			fontWeight: "bold",
		},
		contactForm: {
			backgroundColor: "rgb(231, 225, 250)",
			marginRight: "5vw",
			marginTop: "5vh",
			[theme.breakpoints.down("sm")]: {
				marginTop: 0,
				marginRight: 0,

				paddingTop: "5vh",
			},
		},
	})
);

// function Copyright() {
// 	return (
// 		<Typography
// 			variant="body2"
// 			color="textSecondary"
// 			align="center"
// 			style={{ paddingBottom: "1vh" }}
// 		>
// 			{"Copyright © "}
// 			<Link color="inherit" href="https://material-ui.com/">
// 				Laurine Obriot
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// }

const ContactForm = (props) => {
	const classes = useStyles();

	const [status, setStatus] = useState("Submit");
	const handleSubmit = async (e) => {
		e.preventDefault();
		setStatus("Sending...");
		const { name, email, message } = e.target.elements;
		let details = {
			name: name.value,
			email: email.value,
			message: message.value,
		};
		let response = await fetch("http://localhost:5000/contact", {
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=utf-8",
			},
			body: JSON.stringify(details),
		});
		setStatus("Submit");
		let result = await response.json();
		alert(result.status);
	};

	return (
		<Grid item xs={12} md={5} className={classes.contactForm}>
			<Container component="main" maxWidth="md">
				<Paper className={classes.paper} elevation={3}>
					<form className={classes.form} noValidate onSubmit={handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="name"
									label="What's your name ?"
									name="name"
									autoComplete="name"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Your email ?"
									name="email"
									autoComplete="email"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									name="company"
									label="Company"
									type="company"
									id="company"
									autoComplete="company"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									name="phone"
									label="Phone Number"
									type="phone"
									id="phone"
									autoComplete="phone"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="message"
									label="What can I help you with ?"
									type="message"
									id="message"
									autoComplete="message"
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="#ffffff"
							className={classes.submit}
						>
							{status}
						</Button>
					</form>
				</Paper>
				{/* <Box mt={3}>
					<Copyright />
				</Box> */}
			</Container>
		</Grid>
	);
};

export default ContactForm;
