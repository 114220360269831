import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) =>
	createStyles({
		screen: {
			backgroundColor: "#f1f2fd",
			height: "100%",
			paddingTop: "30vh",
			paddingBottom: "20vh",
			paddingLeft: "20vw",
			paddingRight: "20vw",
			fontFamily: "CeraCompactPro",
			[theme.breakpoints.down("sm")]: {
				paddingLeft: "10vw",
				paddingRight: "10vw",
				paddingTop: "20vh",
			},
		},
		title: {
			textAlign: "center",
			fontSize: 35,
			fontWeight: "bold",
			fontFamily: "CeraCompactPro",
			paddingBottom: "5vh",
		},
		description: {
			textAlign: "center",
			paddingTop: "3vh",
			fontSize: 20,
			marginBottom: "10vh",
			fontFamily: "CeraCompactPro",
		},
		firstTitleArticle: { marginBottom: "2vh" },
		titleInArticle: { marginTop: "5vh", marginBottom: "2vh" },
		textArticle: { marginBottom: "2vh", fontFamily: "CeraCompactPro" },
	})
);

const ArticleCypress = ({ t }) => {
	const classes = useStyles();

	return (
		<Grid className={classes.screen}>
			<Grid className={classes.header}>
				<Typography className={classes.title} variant="h2">
					{t("articleCypressTitle")}
				</Typography>
			</Grid>
			<Grid className={classes.body}>
				<Typography className={classes.textArticle}>
					{t("articleCypressDescription")}
				</Typography>
				<ul>
					<li>{t("articleCypressListAvantagesTests1")}</li>
					<li>{t("articleCypressListAvantagesTests2")}</li>
					<li>{t("articleCypressListAvantagesTests3")}</li>
					<li>{t("articleCypressListAvantagesTests4")}</li>
				</ul>
				<Typography className={classes.textArticle}>
					{t("articleCypressCKOALesTests")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressImplementTitle")}
				</Typography>
				<ol>
					<li>{t("articleCypressListImplement1")}</li>
					<li>{t("articleCypressListImplement2")}</li>
					<li>{t("articleCypressListImplement3")}</li>
					<li>{t("articleCypressListImplement4")}</li>
				</ol>
				<Typography variant="h4" className={classes.titleInArticle}>
					{t("articleCypressTitleInstall")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressAddCypressDependecies")}
				</Typography>
				<Typography className={classes.textArticle}>
					<code>yarn add --dev cypress</code>
				</Typography>
				<Typography className={classes.textArticle}>
					Puis ajouter dans la partie scripts de notre
					<code> package.json </code>
					la commande suivante :
				</Typography>
				<Typography>
					<code>{`{`}</code>
				</Typography>
				<Typography>
					<code>{`"scripts": {`}</code>
				</Typography>
				<Typography>
					<code>{`"start": "react-scripts start",`}</code>
				</Typography>
				<Typography>
					<code>{`"cypress:open": "cypress open"`}</code>
				</Typography>
				<Typography>
					<code>{`}`}</code>
				</Typography>
				<Typography>
					<code>{`}`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressExecuteBinary")}
					<code> ./node_modules/.bin/</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressExplicationCommand")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressLetsTestAppli")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressCodeSandBox")}
				</Typography>
				<Typography variant="h4" className={classes.titleInArticle}>
					{t("articleCypressTitleBootStrapTests")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressPlanDeTests")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressHappyPath")}
				</Typography>
				<ol>
					<li>{t("articleCypressListHappyPath1")}</li>
					<li>{t("articleCypressListHappyPath2")}</li>
					<li>{t("articleCypressListHappyPath3")}</li>
				</ol>
				<Typography className={classes.textArticle}>
					{t("articleCypressAdressServer")}
				</Typography>
				<Typography>
					<code>{`{`}</code>
				</Typography>
				<Typography>
					<code>{`"baseUrl": "http://localhost:3042"`}</code>
				</Typography>
				<Typography>
					<code>{`}`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressAdressServer")}
				</Typography>
				<Typography>
					<code>{`context('Movie search', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`beforeEach(() => {`}</code>
				</Typography>
				<Typography>
					<code>{`cy.visit('/');`}</code>
				</Typography>
				<Typography>
					<code>{`});`}</code>
				</Typography>
				<Typography>
					<code>{` it('Search movie success', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`// test`}</code>
				</Typography>
				<Typography>
					<code>{`});`}</code>
				</Typography>
				<Typography>
					<code>{`});`}</code>
				</Typography>
				<Typography variant="h4" className={classes.titleInArticle}>
					{t("articleCypressTitleSelecteurs")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressWriteFirstTest")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressAddAttributeTestID")}
				</Typography>
				<Typography>
					<code>{`<InputBase`}</code>
				</Typography>
				<Typography>
					<code>{`data-testid="input-search"`}</code>
				</Typography>
				<Typography>
					<code>{`onChange={handleOnChange}`}</code>
				</Typography>
				<Typography>
					<code>{`className={classes.input}`}</code>
				</Typography>
				<Typography>
					<code>{`placeholder="Search Movies 🍿"`}</code>
				</Typography>{" "}
				<Typography>
					<code>{`/>`}</code>
				</Typography>
				<Typography style={{ marginBottom: 20, fontFamily: "CeraCompactPro" }}>
					{t("articleCypressRecupSelecteur")}
				</Typography>
				<Typography>
					<code>{`cy.get('[data-testid=input-search]');`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressNewLibrary")}
				</Typography>
				<Typography>
					<code>yarn add --dev @testing-library/cypress</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressImportCommands")}
				</Typography>
				<Typography>
					<code>import '@testing-library/cypress/add-commands';</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressgetByTestId")}
				</Typography>
				<Typography>
					<code>cy.getByTestId('input-search');</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressAddAttributesToTest")}
				</Typography>
				<Typography>
					<code>{`context('Movie search', () => {
  beforeEach(() => {
    cy.visit('/');
  });

  it('Search movie form', () => {
    cy.getByTestId('input-search')
      .get('input')
      .type('the shining');
    cy.getByTestId('button-search').click();
    cy.getByTestId('movie-title').contains('The Shining');
    cy.getByTestId('movie-year').contains('1980');
  });
});`}</code>
				</Typography>
				<Typography>
					<code>{`context('Movie search', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`beforeEach(() => {`}</code>
				</Typography>
				<Typography>
					<code>{`cy.visit('/');`}</code>
				</Typography>
				<Typography>
					<code>{`});`}</code>
				</Typography>
				<Typography>
					<code>{`it('Search movie form', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`cy.getByTestId('input-search')`}</code>
				</Typography>
				<Typography>
					<code>{`.get('input')`}</code>
				</Typography>
				<Typography>
					<code>{`.type('the shining');`}</code>
				</Typography>
				<Typography>
					<code>{`cy.getByTestId('button-search').click();`}</code>
				</Typography>
				<Typography>
					<code>{`cy.getByTestId('movie-title').contains('The Shining');`}</code>
				</Typography>
				<Typography>
					<code>{`cy.getByTestId('movie-year').contains('1980');`}</code>
				</Typography>
				<Typography>
					<code>{`  });`}</code>
				</Typography>
				<Typography>
					<code>{`  });`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressShinig")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressGreenTests")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressModeHeadless")}
				</Typography>
				<Typography>
					<code>{`{`}</code>
				</Typography>
				<Typography>
					<code>{`"baseUrl": "http://localhost:3000"`}</code>
				</Typography>
				<Typography>
					<code>{`"video":false`}</code>
				</Typography>
				<Typography>
					<code>{`}`}</code>
				</Typography>
				<Typography variant="h4" className={classes.titleInArticle}>
					{t("articleCypressMockCallApi")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressTitleExplicationCallApi")}
				</Typography>
				<Typography>
					<code>{`context('Movie search', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`  beforeEach(() => {`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.visit('/');`}</code>
				</Typography>
				<Typography>
					<code>{`  });`}</code>
				</Typography>
				<Typography>
					<code>{`  it('Search movie form', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.server()`}</code>
				</Typography>
				<Typography>
					<code>{`      .route('GET', 'https://www.omdbapi.com/?apikey=4d7c0bd5&s=the shining', 'fixture:omdbapi-response.json')`}</code>
				</Typography>
				<Typography>
					<code>{`      .as('fetch-movie');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('input-search > input').type('the shining');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('button-search')`}</code>
				</Typography>
				<Typography>
					<code>{`      .click()`}</code>
				</Typography>
				<Typography>
					<code>{`      .wait('@fetch-movie');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('movie-title').contains('The Shining');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('movie-year').contains('1980');`}</code>
				</Typography>
				<Typography>
					<code>{`  });`}</code>
				</Typography>
				<Typography>
					<code>{`});`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressUrlIdentique")}
				</Typography>
				<Typography>
					<code>-https://www.omdbapi.com/?apikey=4d7c0bd5&s=the%20shining</code>
				</Typography>
				<Typography>
					<code>+https://www.omdbapi.com/?apikey=4d7c0bd5&s=the shining</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressFetchMovie")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressFixtureRequete")}
				</Typography>
				<Typography>
					<code>{`{`}</code>
				</Typography>
				<Typography>
					<code>{`  "Search": [`}</code>
				</Typography>
				<Typography>
					<code>{`    {`}</code>
				</Typography>
				<Typography>
					<code>{`      "Title": "The Shining",`}</code>
				</Typography>
				<Typography>
					<code>{`      "Year": "1980",`}</code>
				</Typography>
				<Typography>
					<code>{`      "imdbID": "tt0081505",`}</code>
				</Typography>
				<Typography>
					<code>{`      "Type": "movie",`}</code>
				</Typography>
				<Typography>
					<code>{`      "Poster": "https://m.media-amazon.com/images/M/MV5BZWFlYmY2MGEtZjVk`}</code>
				</Typography>
				<Typography>
					<code>{`  YS00YzU4LTg0YjQtYzY1ZGE3NTA5NGQxXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg"`}</code>
				</Typography>
				<Typography>
					<code>{`    }`}</code>
				</Typography>
				<Typography>
					<code>{`  ],`}</code>
				</Typography>
				<Typography>
					<code>{`  "totalResults": "43",`}</code>
				</Typography>
				<Typography>
					<code>{`  "Response": "True"`}</code>
				</Typography>
				<Typography>
					<code>{`}`}</code>
				</Typography>
				<Typography variant="h4" className={classes.titleInArticle}>
					{t("articleCypressFetchTitle")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressFetchXHR")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressPolyfill")}
				</Typography>
				<Typography>
					<code>
						{`
						import React from 'react'; import ReactDOM from 'react-dom'; import
						App from './App'; import 'whatwg-fetch'; ReactDOM.render(
						<App />, document.getElementById('root'));`}
					</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressUsePolyfill")}
				</Typography>
				<Typography>
					<code>{`context('Movie search', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`  beforeEach(() => {`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.visit('/', {`}</code>
				</Typography>
				<Typography>
					<code>{`      onBeforeLoad: win => {`}</code>
				</Typography>
				<Typography>
					<code>{`        win.fetch = null;`}</code>
				</Typography>
				<Typography>
					<code>{`      },`}</code>
				</Typography>
				<Typography>
					<code>{`    });`}</code>
				</Typography>
				<Typography>
					<code>{`  });`}</code>
				</Typography>
				<Typography>
					<code>{`  it('Search movie form', () => {`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.server().route('GET', 'https://www.omdbapi.com/?apikey=4d7c0bd5&s=the shining', 'fixture:omdbapi-response.json');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.as('fetch-movie');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('input-search > input').type('the shining');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('button-search')`}</code>
				</Typography>
				<Typography>
					<code>{`      .click()`}</code>
				</Typography>
				<Typography>
					<code>{`      .wait('@fetch-movie');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('movie-title').contains('The Shining');`}</code>
				</Typography>
				<Typography>
					<code>{`    cy.getByTestId('movie-year').contains('1980');`}</code>
				</Typography>
				<Typography>
					<code>{`  });`}</code>
				</Typography>
				<Typography>
					<code>{`});`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressRequestIntercept")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressExecuteIntegration")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressLocalTests")}
				</Typography>
				<Typography>
					<code>yarn add --dev concurrently</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressAddScript")}
				</Typography>
				<Typography>
					<code>{`{`}</code>
				</Typography>
				<Typography>
					<code>{`"scripts": {`}</code>
				</Typography>
				<Typography>
					<code>{`"start": "PORT=3042 react-scripts start",`}</code>
				</Typography>
				<Typography>
					<code>{`"test:e2e": "cypress run",`}</code>
				</Typography>
				<Typography>
					<code>{`"test:e2e:ci": "concurrently --kill-others --success=first 'yarn start --progress=false --no-info' 'yarn test:e2e'",`}</code>
				</Typography>
				<Typography>
					<code>{`}`}</code>
				</Typography>
				<Typography>
					<code>{`}`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressTravis")}
				</Typography>
				<Typography>
					<code>{`language: node_js`}</code>
				</Typography>
				<Typography>
					<code>{`node_js:`}</code>
				</Typography>
				<Typography>
					<code>{`  - 'stable'`}</code>
				</Typography>
				<Typography>
					<code>{`addons:`}</code>
				</Typography>
				<Typography>
					<code>{`  apt:`}</code>
				</Typography>
				<Typography>
					<code>{`    packages:`}</code>
				</Typography>
				<Typography>
					<code>{`      # For cypress`}</code>
				</Typography>
				<Typography>
					<code>{`      - libgconf-2-4`}</code>
				</Typography>
				<Typography>
					<code>{`cache:`}</code>
				</Typography>
				<Typography>
					<code>{`  yarn: true`}</code>
				</Typography>
				<Typography>
					<code>{`  directories:`}</code>
				</Typography>
				<Typography>
					<code>{`    - ~/.cache`}</code>
				</Typography>
				<Typography>
					<code>{`script:`}</code>
				</Typography>
				<Typography>
					<code>{`  - yarn test:e2e:ci`}</code>
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressPackageLibConf")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressFetchGoDoc")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressGoTestsFonctionnels")}
				</Typography>
				<Typography className={classes.textArticle}>
					{t("articleCypressGoSandbox")}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default withNamespaces()(ArticleCypress);
